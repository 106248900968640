import { ROLE_DISCIPLINE_TRAINER } from '@monorepo/common/component/Secured';
import CommonHeader from '@monorepo/common/layout/CommonHeader';
import React from 'react';

export default function Header() {
  const menuItems = [
    { url: '/persons', label: 'Rodina' },
    { url: '/orders', label: 'Objednávky' },
    { url: '/disciplines-leader', label: 'Oddíly', authority: ROLE_DISCIPLINE_TRAINER }
  ];

  return <CommonHeader menuItems={menuItems} />;
}
