import NoData from '@monorepo/common/component/NoData';
import QueryWithLoader from '@monorepo/common/component/QueryWithLoader';
import React from 'react';
import { match, Redirect } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import {
  DisciplineConfigForTrainerDocument,
  DisciplineConfigForTrainerQuery,
  DisciplineConfigForTrainerQueryVariables
} from '../../../graphql-operations';
import DisciplineConfigForTrainerView from './DisciplineConfigForTrainerView';

interface DisciplineConfigForTrainerDetailProps {
  match: match<DisciplineConfigDetailUrlParams>;
}

interface DisciplineConfigDetailUrlParams {
  disciplineConfigId?: string;
}
export default function DisciplineConfigForTrainerDetail({ match }: DisciplineConfigForTrainerDetailProps) {
  const { disciplineConfigId } = match.params;

  return (
    <>
      {!!disciplineConfigId && (
        <QueryWithLoader<DisciplineConfigForTrainerQuery, DisciplineConfigForTrainerQueryVariables>
          query={DisciplineConfigForTrainerDocument}
          variables={{ disciplineConfigId }}
        >
          {({ data }) => {
            const disciplineConfigForTrainer = data.disciplineConfigForTrainer;

            if (!disciplineConfigForTrainer) {
              return <Redirect to="/page-not-found" />;
            }

            return <DisciplineConfigForTrainerView disciplineConfigForTrainer={disciplineConfigForTrainer} />;
          }}
        </QueryWithLoader>
      )}

      {!disciplineConfigId && (
        <>
          <Container>
            <Row />
          </Container>
          <NoData text="Vyberte oddíl" />
        </>
      )}
    </>
  );
}
