import { AppConfigContext } from '@monorepo/common/component/AppConfigProvider';
import SelectInput from '@monorepo/common/component/form/SelectInput';
import SexButtonGroup from '@monorepo/common/component/form/SexButtonGroup';
import { EditFormProps, PropChangedHandler, Validator } from '@monorepo/common/component/modal/EditModal';
import { EditRow } from '@monorepo/common/component/modal/EditRow';
import { ServerValidationState, useServerValidation } from '@monorepo/common/component/modal/useServerValidator';
import MultiBadgeLabel from '@monorepo/common/component/MultiBadgeLabel';
import QueryWithLoader from '@monorepo/common/component/QueryWithLoader';
import { PartialNull } from '@monorepo/common/type/Types';
import Sequence from '@monorepo/common/util/Sequence';
import { parseBirthNumber, validateAnyField, validateStringField } from '@monorepo/common/util/Validation';
import React, { useCallback, useContext } from 'react';
import { Alert, Col, CustomInput, Form, FormFeedback, FormGroup, Input, InputGroup, Label, Row } from 'reactstrap';
import { BirthNumberAvailableDocument, CitizenshipsDocument } from '../../graphql-operations';
import { getServerBasename } from '../../util/Url';

function birthNumberValidator(data: PartialNull<EditFormData>) {
  return function validateBirthNumber(value: string | number | null | undefined, label: string): string | undefined {
    if (typeof value !== 'string') {
      return undefined;
    }

    if (!/^\d+$/.test(value)) {
      return 'Rodné číslo musí obsahovat pouze číslice';
    }

    const birthday = data['birthday'];
    const sex = data['sex'];
    const expected = parseBirthNumber(value);

    if (!expected || birthday !== expected?.birthDateString || sex !== expected.sex) {
      return `Nebylo zadáno správné RČ, pokud RČ zadáváte správně, obraťte se na praha-podoli@sokol.eu.`;
    }

    const state = data['birthNumberServerValidation'];
    if (state === ServerValidationState.INVALID) {
      return 'Toto rodné číslo již existuje. Zkuste stránku \'Zapomenuté heslo\' nebo nás kontaktujte na praha-podoli@sokol.eu a problém vyřešíme.';
    }

    return undefined;
  };
}

export const validate: Validator<EditFormData> = (data, touched) => {
  const errors = {};
  validateStringField('degreeBefore', 'Titul', data, errors, touched, { text: { length: { max: 50 } } });
  validateStringField('degreeAfter', 'Titul', data, errors, touched, { text: { length: { max: 50 } } });
  validateStringField('firstName', 'Jméno', data, errors, touched, { required: true, text: { length: { max: 50 } } });
  validateStringField('lastName', 'Příjmení', data, errors, touched, { required: true, text: { length: { max: 50 } } });

  validateStringField('birthNumber', 'Rodné číslo', data, errors, touched, {
    required: true,
    text: { length: { max: 50 } },
    number: {},
    generic: birthNumberValidator(data)
  });
  validateStringField('street', 'Ulice', data, errors, touched, { required: true, text: { length: { max: 50 } } });

  validateStringField('streetNumberP', 'Číslo popisné', data, errors, touched, {
    text: { length: { max: 30 } }
  });
  validateStringField('streetNumberO', 'Číslo orientační', data, errors, touched, {
    text: { length: { max: 30 } }
  });

  if (!data.streetNumberP && !data.streetNumberO) {
    validateStringField('streetNumberP', 'Číslo popisné', data, errors, touched, {
      required: true
    });
    validateStringField('streetNumberO', 'Číslo orientační', data, errors, touched, {
      required: true
    });
  }

  validateStringField('city', 'Město', data, errors, touched, { required: true, text: { length: { max: 50 } } });
  validateStringField('zip', 'PSČ', data, errors, touched, { required: true, number: { range: { min: 0 } } });
  validateAnyField('citizenship', 'Občanství', data, errors, touched, { required: true });
  validateStringField('sex', 'Pohlaví', data, errors, touched, {
    required: true,
    text: {
      oneOfTransformed: [
        {
          name: 'Muž',
          value: 'm'
        },
        {
          name: 'Žena',
          value: 'f'
        }
      ]
    }
  });

  validateStringField('birthday', 'Datum narození', data, errors, touched, {
    required: true
  });
  validateStringField('phone', 'Telefon', data, errors, touched, {
    text: {
      regex: { pattern: /^\d{9}$/, handler: label => `Pole ${label} musí být číslo o 9 číslicích` }
    }
  });

  validateAnyField('gdprAgreement', 'Udělit GDPR souhlas', data, errors, touched, {
    required: true,
    exact: {
      value: true,
      message: label => 'Musíte udělit souhlas GDPR'
    }
  });

  return errors;
};

export interface EditFormData {
  degreeBefore?: string;
  degreeAfter?: string;
  firstName: string;
  lastName: string;
  birthNumber: string;
  birthNumberServerValidation?: ServerValidationState;
  street: string;
  streetNumberP: string;
  streetNumberO: string;
  city: string;
  zip: number;
  sex: string;
  birthday: string;
  phone?: number;
  gdprAgreement: boolean;
  citizenship: {
    id: string;
    name: string;
  };
}

interface ClientDetailNewFamilyProps extends EditFormProps<EditFormData> {}

export default function ClientDetailNewFamily({
  validation,
  onPropChange,
  onPropTouch,
  ...client
}: ClientDetailNewFamilyProps) {
  const [onPropChangeBirthNumberServerValidation] = useServerValidation(
    data => data.birthNumberAvailable,
    value => ({
      birthNumber: value || ''
    }),
    onPropChange,
    'birthNumber',
    'birthNumberServerValidation',
    BirthNumberAvailableDocument
  );

  const onPropChangeBirthNumber: PropChangedHandler<PartialNull<EditFormData>, 'birthNumber'> = useCallback(
    (key, value) => {
      // fill birthday and sex from birth number
      if (value) {
        const birthNumberParseResult = parseBirthNumber(value);
        if (birthNumberParseResult) {
          onPropChange('birthday', birthNumberParseResult.birthDateString);
          onPropChange('sex', birthNumberParseResult.sex);
        } else {
          onPropChange('birthday', undefined);
          onPropChange('sex', undefined);
        }
      } else {
        onPropChange('birthday', undefined);
        onPropChange('sex', undefined);
      }

      // continue
      onPropChangeBirthNumberServerValidation(key, value);
    },
    [onPropChangeBirthNumberServerValidation, onPropChange, onPropTouch]
  );

  const { signUpDisabledMessage } = useContext(AppConfigContext);
  const disabled = !!signUpDisabledMessage;

  return (
    <Form>
      {signUpDisabledMessage && (
        <>
          <Row form>
            <Col xs={12}>
              <Alert color="warning">{signUpDisabledMessage}</Alert>
            </Col>
          </Row>
        </>
      )}

      <Row form>
        <Col sm={12} lg={6}>
          <Row form>
            <Col sm={6}>
              <EditRow
                label="Titul před"
                property="degreeBefore"
                disabled={disabled}
                data={client}
                validation={validation}
                onPropTouch={onPropTouch}
                onPropChange={onPropChange}
              />
            </Col>
            <Col sm={6}>
              <EditRow
                label="Titul za"
                property="degreeAfter"
                disabled={disabled}
                data={client}
                validation={validation}
                onPropTouch={onPropTouch}
                onPropChange={onPropChange}
              />
            </Col>
          </Row>

          <Row form>
            <Col sm={6}>
              <EditRow
                label="Jméno"
                property="firstName"
                disabled={disabled}
                data={client}
                validation={validation}
                onPropTouch={onPropTouch}
                onPropChange={onPropChange}
              />
            </Col>
            <Col sm={6}>
              <EditRow
                label="Příjmení"
                property="lastName"
                disabled={disabled}
                data={client}
                validation={validation}
                onPropTouch={onPropTouch}
                onPropChange={onPropChange}
              />
            </Col>
          </Row>

          <Row form>
            <Col sm={6}>
              <EditRow
                label="Rodné číslo"
                property="birthNumber"
                disabled={disabled}
                data={client}
                validation={validation}
                onPropTouch={onPropTouch}
                onPropChange={onPropChangeBirthNumber}
              />
            </Col>
            <Col sm={6}>
              <EditRow
                label="Datum narození"
                property="birthday"
                disabled={true}
                data={client}
                type="date"
                validation={validation}
                onPropTouch={onPropTouch}
                onPropChange={onPropChange}
              />
            </Col>
          </Row>

          <Row form>
            <Col>
              <EditRow
                label="Ulice"
                property="street"
                disabled={disabled}
                data={client}
                validation={validation}
                onPropTouch={onPropTouch}
                onPropChange={onPropChange}
              />
            </Col>
          </Row>

          <Row form>
            <Col sm={6}>
              <EditRow
                label="Číslo popisné"
                property="streetNumberP"
                disabled={disabled}
                data={client}
                validation={validation}
                onPropTouch={onPropTouch}
                onPropChange={onPropChange}
              />
            </Col>
            <Col sm={6}>
              <EditRow
                label="Číslo orientační"
                property="streetNumberO"
                disabled={disabled}
                data={client}
                validation={validation}
                onPropTouch={onPropTouch}
                onPropChange={onPropChange}
              />
            </Col>
          </Row>

          <Row form>
            <Col sm={9}>
              <EditRow
                label="Město"
                property="city"
                disabled={disabled}
                data={client}
                validation={validation}
                onPropTouch={onPropTouch}
                onPropChange={onPropChange}
              />
            </Col>
            <Col sm={3}>
              <EditRow
                label="PSČ"
                property="zip"
                disabled={disabled}
                data={client}
                validation={validation}
                onPropTouch={onPropTouch}
                onPropChange={onPropChange}
              />
            </Col>
          </Row>

          <FormGroup row>
            <Label for={Sequence.getNext('citizenship')} sm={12}>
              Občanství
            </Label>
            <Col sm={12}>
              <QueryWithLoader query={CitizenshipsDocument}>
                {({ data }) => {
                  return (
                    <SelectInput
                      id={Sequence.getCurrent('citizenship')}
                      selected={client.citizenship || undefined}
                      disabled={disabled}
                      placeholder={'Vyberte občanství'}
                      invalid={!!validation.errors.citizenship}
                      items={data.citizenships}
                      item2id={item => item.id}
                      item2name={item => item.name}
                      onBlur={() => onPropTouch('citizenship')}
                      onSelect={item => onPropChange('citizenship', item)}
                    />
                  );
                }}
              </QueryWithLoader>
              {validation.errors.citizenship && <FormFeedback>{validation.errors.citizenship}</FormFeedback>}
            </Col>
          </FormGroup>
        </Col>

        <Col>
          <EditRow
            label="Telefon"
            property="phone"
            disabled={disabled}
            data={client}
            validation={validation}
            onPropTouch={onPropTouch}
            onPropChange={onPropChange}
          />

          <FormGroup row>
            <Label for="sex" sm={12}>
              Pohlaví
            </Label>
            <Col sm={12}>
              <SexButtonGroup
                // @ts-ignore
                sex={client.sex}
                disabled={true}
                onChange={sex => onPropChange('sex', sex)}
                onBlur={() => onPropTouch('sex')}
              />
              <Input type="hidden" invalid={!!validation.errors.sex} />
              {validation.errors.sex && <FormFeedback>{validation.errors.sex}</FormFeedback>}
            </Col>
          </FormGroup>

          <FormGroup row>
            <MultiBadgeLabel
              label="Udělit GDPR souhlas"
              property="gdprAgreement"
              badges={[
                {
                  text: 'podmínky GDPR',
                  // onClick: () => window.open(`${getServerBasename()}/rest/export/client/universal-gdpr-adult`)
                  downloadUrl: `${getServerBasename()}/rest/export/client/universal-gdpr-adult`,
                  enabled: !disabled
                }
              ]}
              onPropTouch={onPropTouch}
              onPropChange={onPropChange}
            />
            <Col sm={12} className="d-flex align-items-center">
              <InputGroup>
                <CustomInput
                  id="gdprAgreement"
                  type="switch"
                  checked={!!client.gdprAgreement}
                  invalid={!!validation.errors.gdprAgreement}
                  disabled={disabled}
                  onChange={e => onPropChange('gdprAgreement', e.target.checked)}
                  onBlur={() => onPropTouch('gdprAgreement')}
                  className="align-middle"
                />
                {validation.errors.gdprAgreement && <FormFeedback>{validation.errors.gdprAgreement}</FormFeedback>}
              </InputGroup>
            </Col>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
}
