import MoneyLabel from '@monorepo/common/component/MoneyLabel';
import { NoDataWithMarginBottom } from '@monorepo/common/component/NoData';
import OrderLink from '@monorepo/common/component/order/OrderLink';
import StampTypeLabel from '@monorepo/common/component/StampTypeLabel';
import { useShowAll } from '@monorepo/common/hook/useShowAll';
import React from 'react';
import { Button, Card, CardBody, CardHeader, Table } from 'reactstrap';
import { StampTypeEnum } from '../../../graphql-operations';
import StampOfferModal from '../../offer/stamp/StampOfferModal';

interface PaymentStampListProps {
  clientId: string;
  paymentsStamp: {
    id: string;
    year: number;
    type: StampTypeEnum;
    orderStamp: {
      id: string;
      priceDiscounted: number;
      orderTotal: {
        id: string;
        orderNumber: string;
      };
    };
  }[];
}

export default function PaymentStampList({ clientId, paymentsStamp }: PaymentStampListProps) {
  const [paymentsStampLimited, visibleAll, showAll] = useShowAll(4, paymentsStamp);

  return (
    <Card>
      <CardHeader tag="h4" className="text-center">
        Platby známek
      </CardHeader>
      <CardBody>
        <StampOfferModal key={clientId} title="Zaplacení známky" clientId={clientId}>
          {(handleModalOpen, title) => (
            <Button block size="sm" onClick={handleModalOpen} title={title} className="mb-3">
              Přidat
            </Button>
          )}
        </StampOfferModal>

        {!!paymentsStampLimited.length && (
          <div>
            <Table size="sm" striped className="mb-0">
              <thead>
                <tr>
                  <th>Rok</th>
                  <th>Známka</th>
                  <th className="text-right">Částka</th>
                </tr>
              </thead>
              <tbody>
                {paymentsStampLimited.map(paymentStamp => (
                  <tr key={paymentStamp.year}>
                    <td>{paymentStamp.year}</td>
                    <td>
                      {/*@ts-ignore*/}
                      <StampTypeLabel type={paymentStamp.type} short={true} />
                    </td>
                    <td className="text-right">
                      <OrderLink orderNumber={paymentStamp.orderStamp.orderTotal.orderNumber}>
                        <MoneyLabel amount={paymentStamp.orderStamp.priceDiscounted} />
                      </OrderLink>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            {!visibleAll && (
              <Button
                block
                size="sm"
                onClick={() => showAll()}
                title="Zobrazit všechny záznamy"
                className="mt-3 btn-light"
              >
                Zobrazit vše
              </Button>
            )}
          </div>
        )}

        {!paymentsStampLimited.length && <NoDataWithMarginBottom text="Žádné platby" />}
      </CardBody>
    </Card>
  );
}
