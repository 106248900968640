import Secured, { ROLE_DISCIPLINE_TRAINER } from '@monorepo/common/component/Secured';
import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import DisciplineConfigForTrainerDetail from '../component/discipline/trainer/DisciplineConfigForTrainerDetail';
import DisciplineConfigsForTrainerList from '../component/discipline/trainer/DisciplineConfigsForTrainerList';

interface DisciplinesPageProps {
  match: RouteComponentProps;
}

export default function DisciplineConfigsForTrainerPage({ match: { match } }: DisciplinesPageProps) {
  return (
    <Secured authority={ROLE_DISCIPLINE_TRAINER}>
      <Container>
        <Row>
          <Col xs={3}>
            <div className="position-fixed-scrollable">
              <Route
                path={`${match.path}/:disciplineConfigId?`}
                render={props => (
                  <DisciplineConfigsForTrainerList
                    disciplineConfigId={props.match.params.disciplineConfigId}
                    url={match.url}
                  />
                )}
              />
            </div>
          </Col>

          <Col xs={9}>
            <Route path={`${match.path}/:disciplineConfigId`} component={DisciplineConfigForTrainerDetail} />
            <Route exact path={match.path} component={DisciplineConfigForTrainerDetail} />
          </Col>
        </Row>
      </Container>
    </Secured>
  );
}
