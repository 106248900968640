import CapacityProgress from '@monorepo/common/component/CapacityProgress';
import ClientNameLabel from '@monorepo/common/component/client/ClientNameLabel';
import DateRange from '@monorepo/common/component/DateRange';
import NoData, { NoDataWithMarginBottom } from '@monorepo/common/component/NoData';
import TimeLabel from '@monorepo/common/component/TimeLabel';
import { DAYS_OF_WEEK } from '@monorepo/common/util/Time';
import React, { ReactNode } from 'react';
import { Card, CardBody, Col, Container, FormGroup, Row, Table } from 'reactstrap';
import { DisciplineConfigForTrainerQuery } from '../../../graphql-operations';
import { getServerBasename } from '../../../util/Url';

interface LabelRowProps {
  label: ReactNode;
  children: ReactNode;
}

function LabelRow({ label, children }: LabelRowProps) {
  return (
    <Row>
      <Col xs={12} sm={5} className="text-muted">
        {label}
      </Col>

      <Col xs={12} sm={7}>
        {children}
      </Col>
    </Row>
  );
}

interface DisciplineDetailViewProps {
  disciplineConfigForTrainer: NonNullable<DisciplineConfigForTrainerQuery['disciplineConfigForTrainer']>;
}

export default function DisciplineConfigForTrainerView({ disciplineConfigForTrainer }: DisciplineDetailViewProps) {
  const {
    id,
    capacity,
    birthDateFrom,
    birthDateTo,
    leaderForTrainer,
    occupancy,
    discipline,
    clientsForDisciplineTrainer,
    trainersForDisciplineTrainer
  } = disciplineConfigForTrainer;

  return (
    <>
      <Container>
        {/* header with name and New icon */}
        <Row>
          <Col sm="auto">
            <h2>{discipline.name}</h2>
          </Col>
        </Row>

        {/* discipline info */}
        <Row className="mt-3">
          <Col xs={12} lg={6}>
            <LabelRow label="Kdy se cvičí">
              {DAYS_OF_WEEK[discipline.scheduleDay]} (<TimeLabel timeString={discipline.scheduleTimeFrom} /> -{' '}
              <TimeLabel timeString={discipline.scheduleTimeTo} />)
            </LabelRow>
          </Col>
        </Row>

        {/* Tabs with discipline configs */}
        <Row className="mt-3">
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col xs={12} lg={6}>
                    <Row>
                      <Col sm={12} md={5} className="text-muted">
                        Kapacita
                      </Col>
                      <Col sm={12} md={7} className="text-right">
                        {capacity}
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={12} md={5} className="text-muted">
                        Datum narození
                      </Col>
                      <Col sm={12} md={7}>
                        <DateRange dateFromString={birthDateFrom} dateToString={birthDateTo} emptyLabel="bez omezení" />
                      </Col>
                    </Row>

                    <FormGroup row>
                      <Col sm={12} md={5} className="text-muted">
                        Vedoucí
                      </Col>
                      <Col sm={12} md={7}>
                        {leaderForTrainer && <ClientNameLabel client={{ personData: leaderForTrainer }} />}
                      </Col>
                    </FormGroup>

                    <h4 className="mt-3">Cvičitelé</h4>
                    <Row>
                      <Col sm={12}>
                        {!!trainersForDisciplineTrainer.length && (
                          <Table size="sm" striped>
                            <thead>
                              <tr>
                                <th>Člen</th>
                                {/*<th>Role</th>*/}
                              </tr>
                            </thead>
                            <tbody>
                              {trainersForDisciplineTrainer.map(trainer => (
                                <tr key={trainer.id}>
                                  <td>
                                    <ClientNameLabel client={{ personData: trainer }} />
                                  </td>
                                  {/*<td>*/}
                                  {/*  <SokolRoleLabel role={trainer.role} />*/}
                                  {/*</td>*/}
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}

                        {!trainersForDisciplineTrainer.length && <NoDataWithMarginBottom text="Žádní cvičitelé" />}
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={12} lg={6} className="mt-3 mt-lg-0">
                    <h4>Platby členů</h4>
                    {!!clientsForDisciplineTrainer.length && (
                      <>
                        <FormGroup row>
                          <Col sm={12} xl={4} className="text-muted">
                            Obsazenost
                          </Col>
                          <Col sm={12} xl={8}>
                            <CapacityProgress value={occupancy.paidCount} max={capacity} />
                          </Col>
                        </FormGroup>

                        <Table size="sm" striped>
                          <thead>
                            <tr>
                              <th>Člen</th>
                            </tr>
                          </thead>
                          <tbody>
                            {clientsForDisciplineTrainer.map(clientForDisciplineTrainer => (
                              <tr key={clientForDisciplineTrainer.id}>
                                <td>
                                  <ClientNameLabel client={{ personData: clientForDisciplineTrainer }} />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>

                        <a href={getServerBasename() + `/rest/export/attendance/${id}`} download>
                          Docházka
                        </a>
                      </>
                    )}

                    {!clientsForDisciplineTrainer.length && <NoData text="Žádné platby" />}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
