import { useQuery } from '@apollo/client';
import List from '@monorepo/common/component/List';
import React from 'react';
import { DisciplineConfigsForTrainerDocument } from '../../../graphql-operations';

interface DisciplineFilterableListProps {
  disciplineConfigId: string;
  url: string;
}

export default function DisciplineConfigsForTrainerList({ disciplineConfigId, url }: DisciplineFilterableListProps) {
  const { data, loading, error } = useQuery(DisciplineConfigsForTrainerDocument);

  return (
    <List
      items={data?.disciplineConfigsForTrainer}
      loading={loading}
      error={error}
      selectedId={disciplineConfigId}
      idExtractor={dc => dc.id}
      urlExtractor={dc => `${url}/${dc.id}`}
    >
      {dc => <div>{dc.discipline.name}</div>}
    </List>
  );
}
